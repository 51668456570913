import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import isEmpty from 'lodash/isEmpty'

import { BASE_URL } from '@/variables'

import {
  createNotification,
  createWarningNotification,
  createErrorNotification
} from '@/lib/unnotificationsqueue'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import CustomView from '@/lib/uncustomui/components/CustomView'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation'
import ProductsFilter from '@/apps/dido/components/filters/ProductsFilter/ProductsFilter.vue'

@Component({
  props: {
    value: null,
    filterStoreModule: {
      type: String,
      default: 'hierarchiesFilterSelectorStore'
    }
  },
  components: { CrudTable, CustomView, DialogConfirmation },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),
    ...mapState('productsFilter', ['selectedProduct']),
    ...mapState('campaigns', { campaignData: 'currentItem' })
  }
})

class ProductOrdersTable extends Vue {
  storeModule = 'productOrders'
  customOptions = { 'sortBy': ['id'], 'sortDesc': [true] }
  showProductOrderForm = false
  isEditingMode = false
  showDialogConfirmation = false
  selectedProductOrderLine = {}
  currentItem = this.value
  customComponentCatalog = {
    'ProductsFilter': ProductsFilter
  }
  options = {
    context: {
      serverUrl: BASE_URL,
      promoId: this.updatedId,
      reload: true
    }
  }
  form = {}
  loading = false
  confirmOptions = {
    titleDialog: 'Delete',
    textDialog: 'Delete selected product order',
    confirmText: 'Yes',
    confirmColor: 'red'
  }

  get updatedId () {
    return 'calculated__promo_id_field' in this.currentItem ? this.currentItem['calculated__promo_id_field'] : this.currentItem.id
  }

  get campaignId () {
    return 'campaign' in this.currentItem ? this.currentItem['campaign'] : this.currentItem.id
  }

  get includedHierarchiesFilters () {
    return this.$store.getters[`${this.filterStoreModule}/includedFilters`]
  }

  getLinkAttributes () {
    const linkAttributes = {}
    let value = this.crudTableConfig.headers.length > 0 ? this.crudTableConfig.headers[0].value : ''
    linkAttributes['path'] = `item.${value}`
    linkAttributes['name'] = value
    return linkAttributes
  }

  onAddProductOrder () {
    if (this.includedHierarchiesFilters.length) {
      this.showProductOrderForm = true
    } else {
      this.$store.dispatch('addNotification', createWarningNotification(this.$t('promos.noProductFiltersIncluded')))
    }
  }

  /**
   * Populates form with selected product order data
   * @param item
   * @returns {Promise<void>}
   */
  async editProductOrder (item) {
    this.selectedProductOrderLine = item
    this.isEditingMode = true
    await this.$store.commit('productsFilter/setSelectedProduct', { ...item.product })
    this.form = {
      id: item.id,
      banner: item.banner && item.banner.id,
      requested_units: item.requested_units,
      warehouse_pickup_date: item.warehouse_pickup_date,
      description: item.description,
      prop__next_order_units: item.props.next_order_units,
      prop__discount_percentage: item.props.discount_percentage,
      prop__promo_cost: item.props.promo_cost,
      calculated__product_order_volume: item.calculated.product_order_volume
    }
    this.isEditingMode = true
    this.showProductOrderForm = true
  }

  /**
   * Allows create and edit product orders
   * @returns {Promise<void>}
   */
  async saveForm () {
    if (isEmpty(this.selectedProduct)) {
      await this.$store.dispatch('addNotification', createWarningNotification(this.$t('promos.selectProduct')))
    } else if (this.$refs.form.validate()) {
      try {
        this.loading = true
        this.form.product = this.selectedProduct.id
        if (this.isEditingMode) {
          this.selectedProductOrderLine = { promo: this.updatedId, ...this.form }
          await this.$store.dispatch('productOrders/putItemWithBody', {
            pk: this.selectedProductOrderLine.id, body: this.selectedProductOrderLine
          })
        } else {
          try {
            await this.$store.dispatch('campaigns/getCampaign', this.campaignId)
            this.form.banner = this.campaignData.banner
          } finally {
            let newOrder = { promo: this.updatedId, ...this.form }
            await this.$store.dispatch('productOrders/createItemWithBody', newOrder)
          }
        }
        await this.cleanForm()
        await this.$store.dispatch('productOrders/reloadItemList')
      } catch (error) {
        console.log('Error: ', error)
        await this.$store.dispatch('addNotification', createNotification(error.message, error.level))
        this.loading = false
      }
    }
  }

  formInput (items) {
    this.form = items
  }

  /**
   * Clean form and related values when form dialog close
   * @returns {Promise<void>}
   */
  async cleanForm () {
    await this.$store.commit('productsFilter/setSelectedProduct', {})
    this.selectedProductOrderLine = {}
    this.showProductOrderForm = false
    this.isEditingMode = false
    this.form = {}
    this.loading = false
  }

  /**
   * Delete selected product order and reload Crudtable
   * @returns {Promise<void>}
   */
  async onDeleteProductOrder () {
    this.loading = true
    try {
      await this.$store.dispatch('productOrders/deleteItemThrow', this.selectedProductOrderLine.id)
      await this.$store.dispatch('productOrders/reloadItemList')
    } catch {
      await this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
    } finally {
      this.loading = false
      this.showDialogConfirmation = false
    }
  }

  deleteConfirmation (item) {
    this.selectedProductOrderLine = item
    this.showDialogConfirmation = true
  }
}

export default ProductOrdersTable
