<template>
  <Layout :top-bar-title="title" :activeBackButton="false" :sections="sections"
          :baseLayoutInfoUrl="'/base-layout-info/'">
    <v-form @submit.prevent="saveForm" ref="form">
      <v-container>
        <BreadcrumbsField :items="currentItem.promo_items_breadcrumbs"/>
        <p class="display-1 px-3">{{ formTitle }}</p>
        <CustomView
          v-if="showCustomView"
          viewName="promo_detail"
          :value="currentItem"
          @input="updateItem($event)"
          :custom-component-catalog="customComponentCatalog"
          :options="options"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="canEditPromos && canDeletePromos && promoIsCreated"
                 class="ma-2"
                 color="red"
                 dark @click="dialog = true">
            <v-icon right>fa-times</v-icon>
            {{ $t('general.delete') }}
          </v-btn>
          <v-btn v-if="canEditPromos && canAddPromos && promoIsCreated"
                 class="ma-2"
                 :loading="loadingClone"
                 :disabled="loadingClone"
                 color="scondary"
                 @click="loader = 'loadingClone'">
            <v-icon left>fa-clone</v-icon>
            {{ $t('customActions.duplicate') }}
          </v-btn>
          <v-btn v-if="canEditPromos && canAddPromos"
                 class="ma-2"
                 :loading="loadingSaveAndContinue"
                 :disabled="loadingSaveAndContinue"
                 color="primary"
                 @click="loader = 'loadingSaveAndContinue'">
            <v-icon left>fa-save</v-icon>
            {{ $t('customActions.saveAndContinue') }}
          </v-btn>
          <v-btn v-if="canEditPromos && canAddPromos"
                 class="ma-2"
                 :loading="loadingSave"
                 :disabled="loadingSave"
                 color="primary"
                 @click="loader = 'loadingSave'">
            <v-icon left>fa-save</v-icon>
            {{ $t('general.save') }}
          </v-btn>
          <v-dialog
            v-model="dialog"
            max-width="470"
            persistent
          >
            <v-card>
              <v-card-title class="text-center title">
                {{ $t('promos.promoDetailDeleteMessage') }}
              </v-card-title>
              <v-card-text v-html="currentItem.message_confirm_delete"></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="deleteForm"
                >{{ $t('dialogs.confirmOption') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
                >{{ $t('general.cancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-container>
    </v-form>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import CustomView from '../../../lib/uncustomui/components/CustomView'
import NoteLinesTable from '@/apps/dido/components/tables/NoteLinesTable/NoteLinesTable.vue'
import HierarchiesFiltersSelectorWrapper from '@/apps/core/components/HierarchiesFiltersSelectorWrapper/HierarchiesFiltersSelectorWrapper.vue'
import DetachmentsTable from '@/apps/dido/components/tables/DetachmentsTable/DetachmentsTable.vue'
import ProductOrdersTable from '../components/tables/ProductOrdersTable/ProductOrdersTable.vue'
import ProductsFilter from '../components/filters/ProductsFilter/ProductsFilter.vue'
import { mapActions, mapState } from 'vuex'
import { BASE_URL, RESPONSE_LEVEL, IS_PNG } from '@/variables'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import sections from './defaultNavigationDrawerSections'
import {
  createErrorNotification,
  createWarningNotification,
  createSuccessNotification
} from '@/lib/unnotificationsqueue'

export default {
  components: { CustomView, Layout, BreadcrumbsField },
  data () {
    return {
      title: this.$t('promos.sectionName'),
      sections: sections,
      promoId: this.$route.params.id,
      customComponentCatalog: {
        'HierarchiesFiltersSelectorWrapper': HierarchiesFiltersSelectorWrapper,
        'NoteLinesTable': NoteLinesTable,
        'ProductOrdersTable': ProductOrdersTable,
        'DetachmentsTable': DetachmentsTable,
        'ProductsFilter': ProductsFilter
      },
      loader: null,
      loadingSave: false,
      loadingSaveAndContinue: false,
      loadingClone: false,
      dialog: false,
      options: {
        context: {
          serverUrl: BASE_URL,
          promoId: this.promo_id_or_default,
          reload: true
        }
      },
      showCustomView: false
    }
  },
  async mounted () {
    await this.getPromo(this.promoId)
    this.showCustomView = true
  },
  computed: {
    ...mapState('campaigns', { currentCampaign: 'currentItem' }),
    ...mapState('promos', ['currentItem']),
    canEditPromos: {
      get () {
        return this.currentItem.can_edit_promos
      }
    },
    canDeletePromos: {
      get () {
        return this.currentItem.has_delete_promo_perm
      }
    },
    canAddPromos: {
      get () {
        return this.currentItem.has_change_promo_perm
      }
    },
    promoIsCreated () {
      return (Number.isInteger(this.currentItem.id) && this.currentItem.id > 0)
    },
    formTitle () {
      return this.currentItem.hasOwnProperty('verbose_name') && this.currentItem.verbose_name !== '' ? this.currentItem.verbose_name.replace('-', '- Promo') : this.$t('general.newPrimary')
    }
  },
  methods: {
    ...mapActions('campaigns', ['getCampaign']),
    ...mapActions('promos', ['getPromo', 'updateCurrentItem', 'postFormItem',
      'putFormItem', 'cloneItem', 'deleteItem', 'getPromoGroupDetail', 'clearCurrentItem'
    ]),
    ...mapActions({ addNotification: 'addNotification' }),
    promo_id_or_default () {
      let currentId = this.$route.params.id
      if (currentId === null) {
        return -1
      } else {
        return currentId
      }
    },
    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },
    closeForm () {
      var campaignId = this.currentItem.campaign
      this.$refs.form.reset()
      this.clearCurrentItem()
      this.$router.push({
        name: 'CampaignDetail',
        params: { id: campaignId }
      })
    },
    saveForm (redirect = false) {
      if (this.$refs.form.validate()) {
        if (this.promoIsCreated) {
          this.putFormItem(this.currentItem)
            .then(() => {
              this.getPromo(this.promoId)
              if (redirect) {
                this.$emit('save')
                this.closeForm()
              } else {
                this.addNotification(createSuccessNotification(this.$t('dialogs.savedNewValues')))
              }
            })
            .catch(error => {
              this.displayNotificationError(error.response.data, this.$t('dialogs.errorWhenSaving'))
            })
            .finally(() => this.stopLoadingButton())
        } else {
          const campaignAndTopic = { campaign: this.currentCampaign.id, topic: this.currentCampaign.topic }
          this.updateCurrentItem({ ...this.currentItem, ...campaignAndTopic })
          this.postFormItem(this.currentItem)
            .then(() => {
              if (redirect) {
                this.$emit('save')
                this.closeForm()
              }
            })
            .catch(error => {
              this.displayNotificationError(error.response.data, this.$t('dialogs.errorWhenSaving'))
            })
            .finally(() => this.stopLoadingButton())
        }
      } else {
        this.addNotification(createErrorNotification(this.$t('dialogs.errorFormData')))
        this.stopLoadingButton()
      }
    },
    stopLoadingButton () {
      this.loadingSave = false
      this.loadingSaveAndContinue = false
      this.loadingClone = false
    },
    cloneForm () {
      this.putFormItem(this.currentItem)
        .then(() => {
          this.cloneItem(this.currentItem.id)
            .then(response => {
              this.getPromo(response.data.id)
                .then(() => {
                  this.$router.push({
                    name: 'PromoDetail',
                    params: { id: this.currentItem.id }
                  })
                }).finally(() => this.stopLoadingButton())
              this.addNotification(createSuccessNotification(this.$t('dialogs.successDuplicate')))
            })
            .catch((error) => {
              console.error(error)
              this.displayNotificationError(error.response.data, this.$t('dialogs.errorDuplicate'))
              this.stopLoadingButton()
            })
        })
        .catch((error) => {
          console.error(error)
          this.displayNotificationError(error.response.data, this.$t('dialogs.errorWhenSaving'))
          this.stopLoadingButton()
        })
    },
    deleteForm () {
      this.deleteItem(this.currentItem)
        .then(() => {
          this.$refs.form.reset()
          this.clearCurrentItem()
          this.$router.push({
            name: 'CampaignDetail',
            params: { id: this.currentCampaign.id }
          })
        })
        .catch(() => {
          this.addNotification(createErrorNotification(this.$t('campaigns.errorDeletingCampaign')))
        })
    },
    updateItem (items) {
      const props = { ...this.currentItem.props, ...items.props }
      this.updateCurrentItem({ ...this.currentItem, ...items, props: props })
    },
    displayNotificationError (error, defaultMessage = this.$t('general.error')) {
      if (error.hasOwnProperty('level')) {
        if (error.level === RESPONSE_LEVEL.WARNING) {
          this.addNotification(createWarningNotification(error.message))
        } else {
          this.addNotification(createErrorNotification(error.message))
        }
      } else {
        this.addNotification(createErrorNotification(defaultMessage))
      }
    }
  },
  beforeDestroy () {
    this.clearCurrentItem()
  },
  watch: {
    /** DOES NOT ALLOW 0 */
    'currentItem.prop__cost' (value) {
      if (IS_PNG && value) {
        this.updateCurrentItem({ ...this.currentItem, prop__cost_percentage: null })
      } else if (IS_PNG && value === 0 && this.currentItem.prop__cost_percentage) {
        this.updateCurrentItem({ ...this.currentItem, prop__cost: null })
      }
    },
    'currentItem.prop__cost_percentage' (value) {
      if (IS_PNG && value) {
        this.updateCurrentItem({ ...this.currentItem, prop__cost: null })
      } else if (IS_PNG && value === 0 && this.currentItem.prop__cost) {
        this.updateCurrentItem({ ...this.currentItem, prop__cost_percentage: null })
      }
    },
    loader () {
      const l = this.loader
      this[l] = !this[l]

      if (l === 'loadingSave') {
        this.saveForm(true)
      } else if (l === 'loadingSaveAndContinue') {
        this.saveForm(false)
      } else if (l === 'loadingClone') {
        this.cloneForm()
      }

      this.loader = null
    },
    'currentItem.promo_group' () {
      // Force the reloading of the url to get the list of promotion groups
      this.options.context.reload = !this.options.context.reload

      if (this.currentItem.promo_group === null || this.currentItem.promo_group === undefined) {
        this.updateCurrentItem({
          ...this.currentItem,
          promo_group: -1
        })
      }

      if (this.currentItem.promo_group !== -1) {
        this.getPromoGroupDetail()
          .then(response => {
            this.updateCurrentItem({
              ...this.currentItem,
              promo_group_units: response.units,
              promo_group_type: response.type
            })
          })
      } else {
        this.updateCurrentItem({
          ...this.currentItem,
          promo_group_units: null,
          promo_group_type: null
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
