import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { createNotification, createErrorNotification } from '@/lib/unnotificationsqueue'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation'

import { BASE_URL, IS_PNG } from '@/variables'
import { parseFormProps, flattenFormProps } from '@/apps/core/helpers/utils'
import CustomViewDialog from '@/apps/core/components/CustomViewDialog/CustomViewDialog.vue'
import { mapState } from 'vuex'

@Component({
  components: {
    CrudTable,
    CustomViewDialog,
    DialogConfirmation
  },
  props: {
    value: {
      type: Object,
      description: 'Prop value passed through UnCustomUI from PromoDetail (promos currentItem)',
      default: null
    },
    storeModule: {
      type: String,
      description: 'prop from UnCustomBlock fixture',
      required: true
    },
    viewName: {
      type: String,
      description: 'prop from UnCustomBlock fixture',
      required: true
    },
    selectionLimit: {
      type: Number,
      description: 'prop from UnCustomBlock fixture',
      required: false
    },
    type: {
      type: String,
      description: 'prop from UnCustomBlock fixture',
      required: false
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    })
  },
  watch: {
    showDialogConfirmation (val) {
      if (!val) this.currentDetachment = {}
    }
  }
})

class DetachmentsTable extends Vue {
  loading = false
  isEditingMode = false
  showDialogForm = false
  showDialogConfirmation = false
  currentDetachment = {}

  options = {
    context: {
      serverUrl: BASE_URL,
      promoId: this.requiredId,
      reload: true
    }
  }
  customOptions = {
    'sortBy': ['id'],
    'sortDesc': [true]
  }
  confirmOptions = {
    titleDialog: 'Delete',
    textDialog: 'Delete selected detachment',
    confirmText: 'Yes',
    confirmColor: 'red'
  }

  async created () {
    if (this.type) {
      await this.kbdBaseTypeFilter()
    }
  }

  /**
   * Set the KBD type(1 or 2) in the store.
   */
  kbdBaseTypeFilter () {
    this.$store.dispatch(`${this.storeModule}/setBaseType`, this.type)
  }
  /** Get customView Actions (computed property due to this.$t) */
  get customViewActions () {
    return [{ label: this.$t('general.save'), icon: 'fa-save', color: 'primary', event: 'save', validate: true }]
  }
  /**
   * Cannot add more than 1 item in case its a kbd1 type.
   * @returns {boolean}
   */
  get canAddMoreItems () {
    if (this.selectionLimit) {
      if (this.$store.state[this.storeModule].items.length >= this.selectionLimit) {
        return false
      }
    }
    return true
  }

  /**
   * Get CrudTable's parentKey to filter GET requests according to current detail view
   */
  get parentKey () {
    return this.viewName === 'campaign_detachment_modal' ? 'campaign' : 'promo'
  }

  get requiredId () {
    return 'calculated__promo_id_field' in this.value ? this.value['calculated__promo_id_field'] : this.value.id
  }

  /**
   * Returns body to be used in create-update requests, parsing the props from the currentDetachment object
   * @returns {Object}
   */
  get detachmentBody () {
    let parentField = (this.storeModule === 'detachments') ? 'campaign' : 'promo'
    return {
      [parentField]: this.requiredId,
      ...parseFormProps(this.currentDetachment)
    }
  }

  /**
   * Opens form dialog to create a new detachment
   */
  addDetachment () {
    this.currentDetachment = {}
    this.isEditingMode = false
    this.showDialogForm = true
  }

  /**
   * Populates form with selected detachment data
   * @param item
   * @returns {Promise<void>}
   */
  async editDetachment (item) {
    let detachment = await this.$store.dispatch(`${this.storeModule}/getAndReturnItem`, item.id)
    this.currentDetachment = flattenFormProps(detachment)
    this.isEditingMode = true
    this.showDialogForm = true
  }

  get linkAttributes () {
    const linkAttributes = {}
    let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''
    linkAttributes['path'] = `item.${value}`
    linkAttributes['name'] = value
    return linkAttributes
  }

  /**
   * Allows create and edit detachments
   * @returns {Promise<void>}
   */
  async saveForm () {
    try {
      this.loading = true
      this.isEditingMode
        ? await this.$store.dispatch(`${this.storeModule}/putItemWithBody`, {
          pk: this.currentDetachment.id,
          body: this.detachmentBody
        })
        : await this.$store.dispatch(`${this.storeModule}/createItemWithBody`, this.detachmentBody)
      this.cleanForm()
      await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
    } catch (error) {
      console.error(error)
      await this.$store.dispatch('addNotification', createNotification(error.message, error.level))
      this.loading = false
    }
  }

  /**
   * Cleans form and related values when form dialog closes
   */
  cleanForm () {
    this.currentDetachment = {}
    this.showDialogForm = false
    this.isEditingMode = false
    this.loading = false
  }

  /**
   * Called at delete icon click to ask for deletion confirmation
   * @param item {Object} item clicked
   */
  beforeDeleteDetachment (item) {
    this.currentDetachment = item
    this.showDialogConfirmation = true
  }

  /**
   * Delete selected detachment and reload Crudtable
   * @returns {Promise<void>}
   */
  async onDeleteDetachment () {
    this.loading = true
    try {
      await this.$store.dispatch(`${this.storeModule}/deleteItemThrow`, this.currentDetachment.id)
      await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
    } catch (error) {
      if (error.hasOwnProperty('level') && error.hasOwnProperty('message')) {
        await this.$store.dispatch('addNotification', createNotification(error.message, error.level))
      } else {
        await this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
      }
    } finally {
      this.currentDetachment = {}
      this.loading = false
      this.showDialogConfirmation = false
    }
  }

  /** To be removed */
  @Watch('currentDetachment.promo_cost')
  watchPromoCost (value) {
    if (IS_PNG && value) {
      this.currentDetachment.promo_cost_percentage = null
    } else if (IS_PNG && value === 0 && this.currentDetachment.prop__cost_percentage) {
      this.currentDetachment.promo_cost = null
    }
  }

  @Watch('currentDetachment.promo_cost_percentage')
  watchPromoCostPercentage (value) {
    if (IS_PNG && value) {
      this.currentDetachment.promo_cost = null
    } else if (IS_PNG && value === 0 && this.currentDetachment.prop__cost_percentage) {
      this.currentDetachment.promo_cost_percentage = null
    }
  }
}

export default DetachmentsTable
