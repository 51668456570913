import { createErrorNotification } from '@/lib/unnotificationsqueue'
import CustomView from '@/lib/uncustomui/components/CustomView'

export default {
  name: 'CustomViewDialog',
  components: {
    CustomView
  },
  /**
   * Props with (*) Need .sync binding if handleInputAutomatically is set to true:
   * So as to perform 'update:attribute' events
   */
  props: {
    value: {
      type: Object,
      description: 'CustomView\'s value prop (*)',
      required: true
    },
    options: {
      type: Object,
      description: 'CustomView\'s option prop',
      required: true
    },
    customComponentCatalog: {
      type: Object,
      description: 'CustomView\'s components',
      required: false
    },
    viewName: {
      type: String,
      description: 'CustomView\'s viewName prop',
      required: true
    },
    storeModule: {
      type: String,
      description: 'Store Module name to be used',
      required: true
    },
    showDialog: {
      type: Boolean,
      description: 'prop to control dialog render and display (*)',
      required: true
    },
    loading: {
      type: Boolean,
      description: 'Loading prop to control spinners (*)',
      required: true
    },
    actions: {
      type: Array,
      description: 'Action buttons to be displayed: { text, icon, color, event, validate }',
      required: true
    },
    stopInputPropagation: {
      type: Boolean,
      description: 'When true, avoids sending the \'input\' event, as to handle changes automatically: View (*) props',
      default: false
    }
  },
  data () {
    return {
      loadedForm: false
    }
  },
  methods: {
    /**
   * Listens to event from CustomView when it's fully loaded
   */
    onLoadForm () {
      this.loadedForm = true
      this.$emit('view-loaded')
    },

    /**
   * Listens to event from CustomView when a fetch error occurs to close the form dialog
   */
    onLoadFormError () {
      this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
      this.close()
    },

    /**
   * Resets data&prop values and commands dialog closure, which is depending on stopInputPropagation
   */
    close () {
      if (this.stopInputPropagation) {
        this.$emit('update:value', {})
        this.$emit('update:loading', false)
        this.loadedForm = false
        this.$emit('update:showDialog', false)
      } else {
        this.$emit('input', {})
        this.$emit('close')
      }
    },

    onCustomViewInput (item) {
      if (this.stopInputPropagation) {
        this.$emit('update:value', { ...this.value, ...item })
      } else {
        this.$emit('input', item)
      }
    },

    /**
   * Handles action button click to emit action event, may validate form (No support for action event's arguments)
   * @param action {Object} - Action item
   */
    onClickAction ({ event, validate }) {
      if (validate) {
        if (this.$refs.form.validate()) {
          this.$emit(`action:${event}`)
        }
      } else {
        this.$emit(`action:${event}`)
      }
    }
  }
}
